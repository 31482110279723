<nb-layout>
  
  <nb-layout-header>
   
    <div class="header-container">
    
      <div class="logo">
        <button nbButton size="medium" status="warning">
          <span>Dashclicker</span>
        </button>
      </div>
      <div class="alert">
        <p>This project is for sale! Contact me by e-mail <a href="mailto:armands@gmail.com" style="display: inline-block;">armands@gmail.com</a> if you are interested.</p>
     </div>
     
    </div>
  </nb-layout-header>

  <nb-layout-column>
    <app-clicks></app-clicks>
    <app-market></app-market>
    <div class="row">
      <div class="col-sm-6">
        <app-analytics></app-analytics>
      </div>
      <div class="col-sm-6">
        <app-tasks></app-tasks>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4">
        <app-assets></app-assets>
        <app-wallet></app-wallet>
      </div>
      <div class="col-sm-8">
        <app-projects></app-projects>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-boosts></app-boosts>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col">
        <app-companies></app-companies>
      </div>
    </div> -->

    <!-- <nb-alert status="warning">
      <p
        style="
          color: white;
          text-align: center;
          font-weight: bold;
          font-size: 1.5rem;
          line-height: 2rem;
        "
      >
        If you enjoyed this game, please consider giving the developer a tip!
        <br />
        <small>
          I am currently working on a new version of Dashclicker and would
          appreciate if you would support me through this journey.
        </small>
      </p>

      <a
        nbButton
        status="primary"
        href="https://ko-fi.com/armands"
        target="_blank"
        >Donate</a
      >
    </nb-alert> -->
  </nb-layout-column>

  <nb-layout-footer>
    <div class="footer">
      <small
        >Visit
        <a href="https://reddit.com/r/Dashclicker" target="_blank"
          >r/Dashclicker</a
        >
        for the latest updates! <br />You can also view the source code on
        <a href="https://github.com/armn/Dashclicker" target="_blank">GitHub</a
        >.
      </small>
      <button nbButton (click)="openHelp()">Help</button>
    </div>
  </nb-layout-footer>
</nb-layout>
